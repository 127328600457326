const cSkeletonLoad = 'skeleton-load';
const sSkeletonLoad = `.${cSkeletonLoad}`;
const sSkeletonLoadImg = '.skeleton-load__img';
const $skeletonLoad = $(sSkeletonLoad);

export function removeSkeletonAfterLoading($els) {
    const $items = $els || $skeletonLoad.find(sSkeletonLoadImg);

    $items?.each(function () {
        const $skeletonLoadImg = $(this);

        if (!$skeletonLoadImg.attr('data-src')) {
            $skeletonLoadImg.removeClass('skeleton-load__img');
            $skeletonLoadImg.closest(sSkeletonLoad).removeClass(cSkeletonLoad);
        } else {
            $skeletonLoadImg.on('eksmoImgLoadSrcOrBg', () => {
                $skeletonLoadImg.removeClass('skeleton-load__img');
                $skeletonLoadImg.closest(sSkeletonLoad).removeClass(cSkeletonLoad);
            });
        }
    });
}

removeSkeletonAfterLoading();

$('.books-slider_post-load').on('eksmoBooksSliderPostLoaded', (e) => {
    removeSkeletonAfterLoading($(e.target).find(sSkeletonLoadImg));
});

$('body').on('eksmoPagenavAjax', (e) => {
    removeSkeletonAfterLoading($(e.target).find(sSkeletonLoadImg));
});