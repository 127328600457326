import { isLocalhost } from 'Lib/is-localhost/is-localhost';

const $body = $('body');

function onSuccess() {
    console.log('bitrix-events.js successAfterDynamicComposite');
    $('html').toggleClass('is-authorized', (!$('.cheader__item_auth .js-link-auth-forms').length));
    $body.trigger('bitrixDynamicCompositeSuccess.eksmo');
    $body.trigger('bitrixDynamicComposite.eksmo');
}

function onFail() {
    console.log('bitrix-events.js failAfterDynamicComposite');
    $body.trigger('bitrixDynamicCompositeFail.eksmo');
    $body.trigger('bitrixDynamicComposite.eksmo');
}

$body.on('successAfterDynamicComposite.eksmo-bx', onSuccess);
$body.on('failAfterDynamicComposite.eksmo-bx', onFail);

// для локальной верстки
if (isLocalhost()) {
    document.addEventListener('DOMContentLoaded', () => {
        setTimeout(() => {
            $body.trigger('successAfterDynamicComposite.eksmo-bx');
        }, 1500);
    });
}

// Код для стороны бэкенда (разместить сразу после js скриптов битрикса)
// function isDOMContentLoaded(cb){
//     document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', cb) : cb();
// }
//
// if(typeof BX === 'function' && window.frameCacheVars !== undefined){
//     BX.addCustomEvent("onFrameDataReceived", function(json){
//         isDOMContentLoaded(function(){
//             $('body').trigger('successAfterDynamicComposite.eksmo-bx', {detail: json});
//         });
//     });
// }else{
//     isDOMContentLoaded(function(){
//         $('body').trigger('failAfterDynamicComposite.eksmo-bx');
//     });
// }