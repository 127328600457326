export function getObjectGetParams(data) {
    if (data && data?.length) {
        const result = {};
        const arGets = data?.split('&');

        for (let i = 0; i < arGets.length; i++) {
            const [key, value] = arGets[i].split('=');

            if (result[key]) {
                if (Array.isArray(result[key])) {
                    result[key].push(value);
                } else {
                    result[key] = [result[key]];
                    result[key].push(value);
                }
            } else {
                result[key] = value;
            }
        }

        return result;
    }

    return [];
}