export function isLocalStorageEnabled() {
    if (typeof window.isLocalStorageEnabledState === 'boolean') {
        return window.isLocalStorageEnabledState;
    }

    try {
        const mod = '__storage_test__';

        localStorage.setItem(mod, mod);
        localStorage.removeItem(mod);
        window.isLocalStorageEnabledState = true;

        return true;
    } catch (e) {
        window.isLocalStorageEnabledState = false;

        return false;
    }
}