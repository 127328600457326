import { gets } from 'Lib/gets/gets';
import { isLocalhost } from 'Lib/is-localhost/is-localhost';

const isAllowed = (!isLocalhost(true) || !!(gets['test-on-error']));

function send(o) {
    const XHR = window.XDomainRequest || window.XMLHttpRequest;
    const xhr = new XHR();
    const url = `https://jslog.eksmo.net?message=${encodeURIComponent(o.message)}&page=${encodeURIComponent(o.page)}&script=${encodeURIComponent(o.page.split('?')[0] === o.script ? 'inline' : o.script)}&line=${o.line}&col=${o.col}`;

    xhr.open('GET', url, true);
    xhr.timeout = 5000;
    xhr.send();
}

function onError(message, script, line, col) {
    send({
        message: typeof message !== 'string' ? '' : message,
        page: typeof window.location.href !== 'string' ? '' : window.location.href,
        script: typeof script !== 'string' ? '' : script.split('?')[0],
        line: typeof line !== 'number' ? 0 : line,
        col: typeof col !== 'number' ? 0 : col,
    });
}

if (isAllowed) {
    window.onerror = (message, script, line, col) => {
        onError(message, script, line, col);

        return false;
    };

    // function isLoadJS(scriptUrl) {
    //     const script = document.createElement('script');
    //
    //     script.src = scriptUrl;
    //
    //     script.onerror = () => {
    //         onError('404', scriptUrl);
    //     };
    //
    //     document.head.appendChild(script);
    //
    //     return false;
    // }
    //
    // document.addEventListener('DOMContentLoaded', () => {
    //     const scriptsForCheck = [];
    //     const scripts = document.getElementsByTagName('SCRIPT');
    //
    //     for (let i = 0; i < scripts.length; i++) {
    //         if (typeof scripts[i].src === 'string' && scripts[i].src.length) {
    //             scriptsForCheck.push(scripts[i].src);
    //         }
    //     }
    //
    //     for (let i = 0; i < scriptsForCheck.length; i++) {
    //         isLoadJS(scriptsForCheck[i]);
    //     }
    // });
}