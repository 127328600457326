export const eksmoBP = {
    tnMin: 0,
    tnMax: 383,
    xxsMin: 384,
    xxsMax: 479,
    xsMin: 480,
    xsMax: 769,
    smMin: 770,
    smMax: 991,
    mdMin: 992,
    mdMax: 1199,
    lgMin: 1200,
    lgMax: 1599,
    xlMin: 1600,
};