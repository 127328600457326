import FontFaceObserver from 'fontfaceobserver';
import fastdom from 'fastdom';

const $body = $('body');

if (sessionStorage.fontsLoaded) {
    fastdom.mutate(() => {
        document.documentElement.classList.add('fonts-loaded');
        $body.trigger('eksmoFontLoad', [true]);
        $body.trigger('eksmoFontRobotoLoad', [true]);
    });
} else {
    const fontNotoSerif400 = new FontFaceObserver('Noto Serif', { weight: 400 });
    const fontNotoSerif400i = new FontFaceObserver('Noto Serif', { weight: 400, style: 'italic' });
    const fontNotoSerif700 = new FontFaceObserver('Noto Serif', { weight: 700 });
    const fontNotoSerif700i = new FontFaceObserver('Noto Serif', { weight: 700, style: 'italic' });
    const Unbounded = new FontFaceObserver('Unbounded', { weight: 500 });
    const fontRoboto300 = new FontFaceObserver('Roboto', { weight: 300 });
    const fontRoboto300i = new FontFaceObserver('Roboto', { weight: 300, style: 'italic' });
    const fontRoboto400 = new FontFaceObserver('Roboto', { weight: 400 });
    const fontRoboto500 = new FontFaceObserver('Roboto', { weight: 500 });
    const fontRoboto700 = new FontFaceObserver('Roboto', { weight: 700 });
    const fontNotoSans400 = new FontFaceObserver('Noto Sans', { weight: 400 });
    const fontNotoSans700 = new FontFaceObserver('Noto Sans', { weight: 700 });

    $.when(
        fontRoboto400.load(null, 7000),
    ).then(() => {
        $body.trigger('eksmoFontRobotoLoad', [true]);

        $.when(
            fontNotoSerif400.load(null, 5000),
            fontNotoSerif400i.load(null, 5000),
            fontNotoSerif700.load(null, 5000),
            fontNotoSerif700i.load(null, 5000),
            Unbounded.load(null, 5000),
            fontRoboto300.load(null, 5000),
            fontRoboto300i.load(null, 5000),
            fontRoboto400.load(null, 5000),
            fontRoboto500.load(null, 5000),
            fontRoboto700.load(null, 5000),
            fontNotoSans400.load(null, 5000),
            fontNotoSans700.load(null, 5000),
        ).then(() => {
            fastdom.mutate(() => {
                document.documentElement.classList.add('fonts-loaded');
                sessionStorage.fontsLoaded = true;
                $body.trigger('eksmoFontLoad', [true]);
            });
        }).catch(() => {
            sessionStorage.fontsLoaded = false;
            $body.trigger('eksmoFontLoad', [false]);
        });
    }).catch(() => {
        sessionStorage.fontsLoaded = false;
        $body.trigger('eksmoFontLoad', [false]);
    });
}