import { getW, getH } from 'Lib/w/w';
import { getNumber } from 'Lib/get-number/get-number';

export function isOnScreen($e, vOffset, hOffset, eThreshold) {
    let $el = $e;
    let verticalOffset = vOffset;
    let horizontalOffset = hOffset;
    let threshold = eThreshold;

    if ($el.length) {
        $el = $el.first();
        verticalOffset = getNumber(verticalOffset);
        horizontalOffset = getNumber(horizontalOffset);
        threshold = (typeof threshold === 'number' && threshold >= 0 && threshold <= 1) ? threshold : 0;

        const elSt = $el.offset().top;
        const elSl = $el.offset().left;
        const elW = $el.outerWidth();
        const elH = $el.outerHeight();
        const windowSt = $(window).scrollTop();
        const windowH = getH();
        const windowW = getW();
        const $fixedHeader = $('.cheader_fixed');
        const screen = {
            'top': windowSt + ($fixedHeader.length ? $fixedHeader.outerHeight() : 0) - verticalOffset,
            'bottom': windowSt + windowH + verticalOffset,
            'left': 0 - horizontalOffset,
            'right': windowW + horizontalOffset,
        };
        const el = {
            'top': elSt,
            'bottom': elSt + elH,
            'left': elSl,
            'right': elSl + elW,
        };

        if ((el.bottom > screen.top && el.top < screen.bottom) && (el.right > screen.left && el.left < screen.right)) {
            if (threshold) {
                const showHeight = Math.min(el.bottom - screen.top, screen.bottom - el.top);
                const showWidth = Math.min(el.right - screen.left, screen.right - el.left);

                return ((showHeight >= windowH || showHeight / elH >= threshold) && (showWidth >= windowW || showWidth / elW >= threshold));
            }

            return true;
        }
    }

    return false;
}