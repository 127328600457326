import { isLocalhost } from 'Lib/is-localhost/is-localhost';

export class CheckboxAccept {
    constructor(name, checkboxContSelector, checkboxInputSelector, checkboxTextSelector, submitSelector, elForm) {
        this._checkboxContSelector = checkboxContSelector;
        this._checkboxInputSelector = checkboxInputSelector;
        this._submitSelector = submitSelector;
        this._checkboxTextSelector = checkboxTextSelector;
        this._elForm = elForm;
        this._elCheckboxInputs = this._elForm?.querySelectorAll(this._checkboxInputSelector) || null;
        this._elFormSubmit = this._elForm?.querySelector(this._submitSelector) || null;
        this._checkedFirst = false;

        this._formData = {
            'checkbox': {
                'name': name,
                'text': '',
                'value': '',
            },
            'form': {},
            'formId': '',
            'page': window.location.pathname,
        };
    }

    static _checkPromise(data) {
        if (data.ok) {
            return Promise.resolve(data);
        }

        return Promise.reject(new Error(`Ошибка: ${data.status}`));
    }

    _getHash() {
        const url = isLocalhost() ? `/eksmo/_verstka/ajax/hash`
            : `/ajax/form/?fn=${this._dataFn}`;

        return fetch(url)
            .then((data) => CheckboxAccept._checkPromise(data)).then((data) => data.text())
            .catch((err) => console.log(`CheckboxAccept._getHash() - ${err}`));
    }

    _postData() {
        return fetch('/ajax/user/checkboxes/', {
            method: 'POST',
            headers: {
                Accept: 'application/json; odata=verbose',
            },
            body: JSON.stringify(this._formData),
        })
            .then((data) => CheckboxAccept._checkPromise(data))
            .catch((err) => console.log(`CheckboxAccept._postData() - ${err}`));
    }

    _getInputs() {
        const elsInput = Array.from(this._elForm.querySelectorAll('input'));
        const elsTextarea = Array.from(this._elForm.querySelectorAll('textarea'));
        const allFields = elsInput.concat(elsTextarea);
        const data = {};

        allFields.forEach((input, i) => {
            if (input.name) {
                data[input.name] = input.value;
            } else {
                data[i] = input.value;
            }
        });

        return data;
    }

    _changeCheckbox(item) {
        const elCheckboxCont = item.closest(this._checkboxContSelector);

        this._formData.form = this._getInputs();
        this._formData.checkbox.value = item.checked;
        this._formData.checkbox.text = elCheckboxCont.querySelector(this._checkboxTextSelector).textContent;
        this._dataFn = elCheckboxCont.getAttribute('data-fn');

        if (!this._checkedFirst) {
            this._getHash().then((hash) => {
                this._formData.formId = hash;
            })
                .then(() => this._postData());

            this._checkedFirst = true;
        } else {
            this._postData();
        }
    }

    setEventCheckboxAccept() {
        this._elCheckboxInputs?.forEach((item) => {
            item?.addEventListener('change', () => {
                this._changeCheckbox(item);
                this._stateCheckbox = [...this._elCheckboxInputs].every((itemInner) => itemInner.checked);

                if (this._stateCheckbox) {
                    this._elFormSubmit?.classList.remove('btn_disabled');
                    this._elFormSubmit?.removeAttribute('disabled');
                } else {
                    this._elFormSubmit?.classList.add('btn_disabled');
                    this._elFormSubmit?.setAttribute('disabled', 'disabled');
                }
            });
        });
    }
}