import { speed } from 'Lib/speed/speed';
import { oLike } from 'Lib/like/like';
import { getW } from 'Lib/w/w';


export default function (data) {
    const timerId = setInterval(() => {
        if (document.querySelector('.basket-list-mobile__item') || document.querySelector('.basket-list-desktop__item')) {
            clearInterval(timerId);

            // eslint-disable-next-line no-inner-declarations
            function outputTemplateLike(dataItem, signature) {
                return `
                    <div class="white-label__like like js-book-basket-like"
                         data-type="products"
                         data-id="${dataItem.guid}" data-bdl-id="${dataItem.nomcode}">
                        <svg class="like__svg like__svg_n">
                            <use xlink:href="#like"></use>
                        </svg>
                        <svg class="like__svg like__svg_y">
                            <use xlink:href="#like-color"></use>
                        </svg>

                        ${signature ? `<span class="like__text like__text_n like__text_no-style like__text_block">В избранное</span><span class="like__text like__text_y like__text_no-style like__text_block">В избранном</span>` : ''}
                    </div>
                `;
            }

            // eslint-disable-next-line no-inner-declarations
            function insertLike(selectorList, selectorWhereInsertBlock, signature = false) {
                Object.keys(data).forEach((i) => {
                    document.querySelectorAll(selectorList).forEach((itemSelector) => {
                        if (Number(data[i]?.book24) === Number(itemSelector.getAttribute('data-product-id')) && !itemSelector.querySelector('.white-label__like')) {
                            itemSelector.querySelector(selectorWhereInsertBlock).insertAdjacentHTML('beforeEnd', outputTemplateLike(data[i], signature));
                        }
                    });
                });
            }

            if (getW() < 1025) {
                insertLike('.basket-list-mobile__item', '.basket-product-item-m__name');
            } else {
                insertLike('.basket-list-desktop__item', '.basket-product-item__body', true);
            }

            oLike.init('.js-book-basket-like');
        }
    }, speed * 3);
}