import { isOnScreen } from 'Lib/is-on-screen/is-on-screen';
import 'imports-loader?wrapper=window!jquery-throttle-debounce'; // eslint-disable-line import/no-extraneous-dependencies, import/no-webpack-loader-syntax
import { speed } from 'Lib/speed/speed';
import { getNumber } from 'Lib/get-number/get-number';

const $window = $(window);
const listForPolyfill = new Set();

// Функция-полифил для onScreen, если браузер не поддерживает IntersectionObserver
function onScreenPolyfill($element, callBack, verticalOffset, horizontalOffset, threshold) {
    let $el = $element;

    $el.each(function () {
        const $thisEl = $(this);

        if (!listForPolyfill.has($thisEl[0]) && isOnScreen($thisEl, verticalOffset, horizontalOffset, threshold)) {
            callBack($thisEl);
            $el = $el.not($thisEl);
            listForPolyfill.add($thisEl[0]);
        }
    });

    if (!$el.length) {
        $window.off('resize.onScreen', () => {
            onScreenPolyfill($el, callBack, verticalOffset, horizontalOffset, threshold);
        });

        $window.off('scroll.onScreen', $.throttle(speed * 2, () => {
            onScreenPolyfill($el, callBack, verticalOffset, horizontalOffset, threshold);
        }));
    }
}

export function onScreen($element, callBack, verticalOffsetValue, horizontalOffsetValue, thresholdValue) {
    const $el = $element;

    // Завершаем работу функции если $el не является jquery селектором
    if (!($el instanceof jQuery && $el.length)) {
        return;
    }

    const verticalOffset = getNumber(verticalOffsetValue);
    const horizontalOffset = getNumber(horizontalOffsetValue);
    const threshold = (typeof thresholdValue === 'number' && thresholdValue >= 0 && thresholdValue <= 1) ? thresholdValue : 0;

    if ('IntersectionObserver' in window) { // Проверка поддержки браузером IntersectionObserver
        const io = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    callBack($(entry.target));
                    io.unobserve(entry.target);
                }
            });
        }, { rootMargin: `${verticalOffset}px ${horizontalOffset}px`, threshold });

        $el.each(function () {
            io.observe($(this)[0]);
        });
    } else {
        onScreenPolyfill($el, callBack, verticalOffset, horizontalOffset, threshold);

        $window.on('resize.onScreen', () => {
            onScreenPolyfill($el, callBack, verticalOffset, horizontalOffset, threshold);
        });

        $window.on('scroll.onScreen', $.throttle(speed * 2, () => {
            onScreenPolyfill($el, callBack, verticalOffset, horizontalOffset, threshold);
        }));
    }
}