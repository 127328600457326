import { getCookie } from 'Lib/cookies/cookies';

export class AbTest {
    constructor(...distributions) {
        this._userID = getCookie('eid');
        this._percent = parseInt(this._userID.substr(4, 2)) + 1;
        this._distributions = distributions;
        this._defaultResult = 1;
        this._getResult = this._getVariant();
    }

    _getSets() {
        return this._distributions.reduce((accumulator, currentValue) => {
            const prevIterationItem = accumulator.slice(-1)[0];
            const prevIterationSecondNumber = prevIterationItem ? prevIterationItem[1] : 0;

            accumulator.push([prevIterationSecondNumber + 1, prevIterationSecondNumber + currentValue]);

            return accumulator;
        }, []);
    }

    _getVariant() {
        const sumPercent = this._distributions.reduce((sum, value) => sum + value);
        const allItemIsNumber = !!this._distributions.every((item) => !Number.isNaN(parseInt(item)));

        if (allItemIsNumber && sumPercent === 100) {
            const sets = this._getSets();
            const resultIndex = sets.findIndex((set) => (this._percent >= set[0] && this._percent <= set[1]));

            if (typeof resultIndex === 'number') {
                return resultIndex + 1;
            }
        }

        return this._defaultResult;
    }

    get() {
        return this._getResult;
    }
}