window._getW = window.innerWidth || $(window).width();
window._getH = window.innerHeight || $(window).height();

$(window).on('resize', () => {
    window._getW = window.innerWidth || $(window).width();
    window._getH = window.innerHeight || $(window).height();
});

$('body').on('eksmoResizeWidth', () => {
    window._getW = window.innerWidth || $(window).width();
    window._getH = window.innerHeight || $(window).height();
});

export function getW() {
    return window._getW;
}

export function getH() {
    return window._getH;
}