import { isRetina } from 'Lib/isretina/isretina';
import fastdom from 'fastdom';

export function loadSrcOrBg($els) {
    fastdom.mutate(() => {
        $els.each(function () {
            const $this = $(this);
            let srcSet = '';
            let attrSrcSet = '';
            let alt = '';
            let attrAlt = '';
            let dataClass = '';
            let attrDataClass = '';
            let srcForLoadEvent = '';
            const sLoader = '.js-loadsrcorbg-loader';
            let isImgOrIframe = false;
            const $parentPicture = $this.closest('picture');

            if ($this.attr('data-srcset')?.length) {
                srcSet = $this.attr('data-srcset');
            }

            if ($this.attr('data-alt')?.length) {
                alt = $this.attr('data-alt');
            }

            if ($this.attr('data-class')?.length) {
                dataClass = $this.attr('data-class');
            }

            if ($this.attr('data-src')?.length) {
                srcForLoadEvent = $this.attr('data-src');

                if ($this[0].tagName === 'IMG' || $this[0].tagName === 'IFRAME') {
                    isImgOrIframe = true;

                    if ($parentPicture.length) {
                        const $source = $parentPicture.find('source');

                        $source.each(function () {
                            const $thisSource = $(this);
                            const dataSrcset = $thisSource.attr('data-srcset');

                            if (dataSrcset?.length) {
                                $thisSource.attr('srcset', dataSrcset);
                                $thisSource.removeAttr('data-srcset');
                            }
                        });
                    }

                    $this.attr('src', $this.attr('data-src'));

                    if (srcSet.length) {
                        $this.attr('srcset', srcSet);
                    }

                    if (alt.length) {
                        $this.attr('alt', alt);
                    }

                    if (dataClass.length) {
                        $this.attr('class', dataClass);
                    }
                } else {
                    const $loader = $this.find(sLoader);

                    if (srcSet.length) {
                        attrSrcSet = ` srcset="${srcSet}"`;
                    }

                    if (alt.length) {
                        attrAlt = ` alt="${alt}"`;
                    }

                    if (dataClass.length) {
                        attrDataClass = ` class="${dataClass}"`;
                    }

                    if ($loader.length && !$loader.siblings().length) {
                        $this.on('eksmoImgLoadSrcOrBg', () => {
                            $loader.remove();
                        });

                        $this.append(`<img src="${$this.attr('data-src')}"${attrSrcSet}${attrAlt}${attrDataClass}>`);
                    } else {
                        $this.html(`<img src="${$this.attr('data-src')}"${attrSrcSet}${attrAlt}${attrDataClass}>`);
                    }
                }

                if (srcSet.length && isRetina()) {
                    srcForLoadEvent = srcSet.replace(/ 2x/g, '');
                }
            } else if ($this.attr('data-bg')?.length) {
                srcForLoadEvent = $this.attr('data-bg');
                $this.attr('style', `background-image:url(${$this.attr('data-bg')});`);
            }

            $this.removeAttr('data-src');
            $this.removeAttr('data-srcset');
            $this.removeAttr('data-alt');
            $this.removeAttr('data-bg');
            $this.removeAttr('data-class');

            if (isImgOrIframe) {
                $this[0].onload = () => {
                    $this.trigger('eksmoImgLoadSrcOrBg');
                };
            } else if (srcForLoadEvent?.length) {
                const img = new Image();

                img.onload = () => {
                    $this.trigger('eksmoImgLoadSrcOrBg');
                };

                img.src = srcForLoadEvent;
            }
        });
    });
}