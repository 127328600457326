import { isLocalStorageEnabled } from 'Lib/is-local-storage-enabled/is-local-storage-enabled';
import { isLocalhost } from 'Lib/is-localhost/is-localhost';
import { isObject } from 'Lib/is-object/is-object';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';
import { AbTest } from 'Lib/ab-test/ab-test';
import { speed } from 'Lib/speed/speed';

const _isAllowed = (!isLocalhost());
const _keyNameLastGAClickListName = '_eksmoLastGAClickListName';
const $body = $('body');

// Отправка данных в Google dataLayer
function _sendToDL(data) {
    if (_isAllowed) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push(data);
    }

    console.log(' ♑ _sendToDL()', data);
}

// Хелпер для проверки типов (можно переделать)
function _isResolvedTypeItem(value) {
    return ((typeof value === 'string' || typeof value === 'number' || Array.isArray(value) || isObject(value)) && value !== 'undefined');
}

// Проверка каждого элемента переданного массива на непустую строку
function _isNoEmptyStrings(strings) {
    return strings.every(isNoEmptyString);
}

function setParamsEventCategory(isPreorder, price) {
    let eventCategory;

    if (isPreorder) {
        eventCategory = 'pre_order';
    } else if (price) {
        eventCategory = 'in_stock';
    } else {
        eventCategory = 'out_of_stock';
    }

    return eventCategory;
}

export const eventSender = {
    settings: {
        viewedBook: [],
        viewedPromo: [],
        viewedBookGA4: [],
        viewedPromoGA4: [],
    },

    // Кастомная отправка данных в Google dataLayer
    customDL(oDataLayer) {
        _sendToDL(oDataLayer);
    },

    // Отправка данных в Mindbox о просмотренной книге
    setViewBookMD() {
        let typeBook = '';
        const bookId = document.querySelector('.book-page').getAttribute('data-dmp-book-guid');

        if ($body.find('.prices-card__tab_paper.prices-card__tab_active').length || $body.find('.prices-card__tab_paper.tabs__link_active').length) {
            typeBook = 'p';
        } else if ($body.find('.prices-card__tab_el.prices-card__tab_active').length || $body.find('.prices-card__tab_el.tabs__link_active').length) {
            typeBook = 'e';
        } else {
            typeBook = 'a';
        }

        if (bookId && bookId.length) {
            window.mindbox('async', {
                operation: 'Website.ViewProduct',
                data: {
                    viewProduct: {
                        product: {
                            ids: {
                                website: `${typeBook}-${bookId}`,
                            },
                        },
                    },
                },
                onSuccess() {
                    console.log(' ♑ ViewProductMD()', `${typeBook}-${bookId}`);
                },
                onError(error) {
                    console.log(`Ошибка MD - ${error}`);
                },
            });

            let bookPrice = document.querySelector('.book-page').getAttribute('data-book-price');

            if (!bookPrice || !bookPrice.length) {
                bookPrice = 0;
            }

            if (!isLocalhost(true)) {
                window.mindbox('async', {
                    operation: 'AddProductToViewedProducts',
                    data: {
                        addProductToList: {
                            product: {
                                ids: {
                                    website: `${typeBook}-${bookId}`,
                                },
                            },
                            pricePerItem: `${bookPrice}`,
                        },
                    },
                    onSuccess() {
                        console.log(' ♑ ViewedProductMD()', `${typeBook}-${bookId}`);
                    },
                    onError(error) {
                        console.log(`Ошибка MD - ${error}`);
                    },
                });
            }
        }
    },

    // Событие клика на пункты в главном меню GA-4
    clickMainMenu(itemMenu) {
        this.customDL({
            event: 'eksmo',
            eventAction: 'click',
            eventCategory: 'menu_click',
            eventLabel: itemMenu.text().trim(),
        });
    },

    // Отправка номкодов на эксмо, для последующей передачи в Mindbox на бэке
    sendBooksInBasketMD(arrBooks) {
        return fetch('/ajax/mb/cart/',
            {
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(arrBooks),
            })
            .then((data) => {
                if (data.ok) {
                    return Promise.resolve(data.json());
                }

                return Promise.reject(new Error(`Ошибка: ${data.status}`));
            })
            .then((data) => {
                console.log(' ♑ sendBooksInBasketMD()', arrBooks);

                return data;
            });
    },

    // Отправка данных в Mindbox о книгах добавленных в избранное
    setWishListMD() {
        fetch('/ajax/like/user/?type=book', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
        })
            .then((data) => {
                if (data.ok) {
                    return Promise.resolve(data.json());
                }

                return Promise.reject(new Error(`Ошибка: ${data.status}`));
            })
            .then((data) => {
                this._BooksItems = data;

                const productList = [];

                if (Object.keys(this._BooksItems).length) {
                    Object.keys(this._BooksItems).forEach((key) => {
                        // const guid = this._BooksItems[key]?.BOOK?.guid;
                        const guid = this._BooksItems[key]?.GUID;
                        const price = this._BooksItems[key]?.BOOK?.price;
                        let type;

                        if (this._BooksItems[key]?.TYPE === 'products_audio') {
                            type = 'a';
                        } else if (this._BooksItems[key]?.TYPE === 'products_electro') {
                            type = 'e';
                        } else {
                            type = 'p';
                        }

                        if (guid && price && price !== 'undefined') {
                            productList.push({
                                product: {
                                    ids: {
                                        website: `${type}-${guid}`,
                                    },
                                },
                                count: 1,
                                pricePerItem: price,
                            });
                        }
                    });
                }

                window.mindbox('async', {
                    operation: 'Website.setWishList',
                    data: {
                        productList,
                    },
                    onSuccess() {
                        console.log(' ♑ setWishListMD()', {
                            operation: 'Website.SetWishList',
                            data: {
                                productList,
                            },
                        });
                    },
                    onError(error) {
                        console.log(`Ошибка MD - ${error}`);
                    },
                });
            });
    },

    impressionsClickAddBasketBookGA4(objInfoBook, typeEvent) {
        switch (typeEvent) {
        case 'impressions':
            this.impressionsGA4(objInfoBook);
            break;
        case 'addBasket':
            this.addGA4(objInfoBook);
            break;
        case 'click':
            this.clickGA4(objInfoBook);
            break;
        case 'wishList':
            this.setWishListGA4(objInfoBook);
            break;
        case 'wishListCollections':
            this.setWishListGA4(objInfoBook);
            break;
        default:
            break;
        }
    },

    // Добавление книги в избранное GA-4
    setWishListGA4(objBook) {
        if (isObject(objBook)) {
            Object.keys(objBook)
                .forEach((k) => {
                    if (!_isResolvedTypeItem(objBook[k])) {
                        delete objBook[k];
                    }
                });

            if (_isNoEmptyStrings([
                objBook.id,
                objBook.name,
            ])) {
                _sendToDL({
                    'event': 'add_to_wishlist',
                    'eventCategory': objBook.typeWishList,
                    'ecommerce': {
                        'currency': 'RUB',
                        'value': objBook.price || null,
                        'items': [{
                            'item_name': objBook.name,
                            'item_id': objBook.id,
                            'discount': objBook.discountPrice || null,
                            'price': objBook.oldPrice || objBook.price || null,
                            'item_brand': objBook.brand || '',
                            'item_category': objBook.firstCategory || '',
                            'item_category2': objBook.lastCategory || '',
                            'item_category3': objBook.author || '',
                            'item_category4': objBook.type || '',
                        }],
                    },
                });
            }
        }
    },

    // Событие клика на превью товара GA-4
    clickGA4(objBook) {
        if (isObject(objBook)) {
            Object.keys(objBook).forEach((k) => {
                if (!_isResolvedTypeItem(objBook[k])) {
                    delete objBook[k];
                }
            });

            if (_isNoEmptyStrings([
                objBook.id,
                objBook.name,
            ])) { // обязательные поля
                _sendToDL({
                    'event': 'select_item',
                    'eventCategory': null,
                    'ecommerce': {
                        'item_list_name': objBook.list || null,
                        'items': [
                            {
                                'item_name': objBook.name,
                                'item_id': objBook.id,
                                'currency': 'RUB',
                                'discount': objBook.discountPrice || null,
                                'price': objBook.oldPrice || objBook.price || null,
                                'item_brand': objBook.brand || '',
                                'item_category': objBook.firstCategory || '',
                                'item_category2': objBook.lastCategory || '',
                                'item_category3': objBook.author || '',
                                'item_category4': objBook.type || '',
                                'index': objBook.index || '',
                            },
                        ],
                    },
                });

                if (isLocalStorageEnabled()) {
                    localStorage.setItem(_keyNameLastGAClickListName, objBook.list);
                }
            }
        }
    },

    // Событие просмотра страницы товара GA-4
    detailGA4({ price, name, id, isPreorder, discountPrice, oldPrice, brand, lastCategory, firstCategory, author, type }) {
        _sendToDL({
            'event': 'view_item',
            'eventCategory': setParamsEventCategory(isPreorder, price),
            'ecommerce': {
                'currency': 'RUB',
                'value': price || null,
                'items': [{
                    'item_name': name || '',
                    'item_id': id || null,
                    'discount': discountPrice || null,
                    'price': oldPrice || price || null,
                    'item_brand': brand || '',
                    'item_category': firstCategory || '',
                    'item_category2': lastCategory || '',
                    'item_category3': author || '',
                    'item_category4': type || '',
                }],
            },
        });
    },

    // Событие просмотра превью товара
    impressionsGA4(objBook) {
        if (isObject(objBook)) {
            const arRes = [];

            Object.keys(objBook)
                .forEach((k) => {
                    if (!_isResolvedTypeItem(objBook[k])) {
                        delete objBook[k];
                    }
                });

            if (_isNoEmptyStrings([
                objBook.id,
                objBook.name,
            ])) { // обязательные поля
                if (!(objBook.list in this.settings.viewedBookGA4)) {
                    this.settings.viewedBookGA4[objBook.list] = [];
                }

                if (!this.settings.viewedBookGA4[objBook.list].includes(objBook.id)) {
                    this.settings.viewedBookGA4[objBook.list].push(objBook.id);
                    arRes.push(objBook);
                }
            }

            if (arRes.length) {
                _sendToDL({
                    'event': 'view_item_list',
                    'eventCategory': setParamsEventCategory(objBook.isPreorder, objBook.price),
                    'ecommerce': {
                        'currency': 'RUB',
                        'item_list_name': objBook.list || null,
                        'items': [{
                            'item_name': objBook.name || '',
                            'item_id': objBook.id || null,
                            'discount': objBook.discountPrice || null,
                            'price': objBook.oldPrice || objBook.price || null,
                            'item_brand': objBook.brand || '',
                            'item_category': objBook.firstCategory || '',
                            'item_category2': objBook.lastCategory || '',
                            'item_category3': objBook.author || '',
                            'item_category4': objBook.type || '',
                        }],
                    },
                });
            }
        }
    },

    // Добавление товара в корзину GA-4
    addGA4({ price, name, id, discountPrice, oldPrice, list, brand, lastCategory, firstCategory, author, type, textBtnBasket }) {
        let eventLabel = '';

        if (Number(price) >= 1500) {
            const abTestEsHit = new AbTest(50, 50);

            if (abTestEsHit.get() === 1) {
                eventLabel = 'ab_main';
            } else if (abTestEsHit.get() === 2) {
                eventLabel = 'ab_short';
            }
        }

        _sendToDL({
            'event': 'add_to_cart',
            'eventCategory': textBtnBasket || '',
            'eventLabel': eventLabel,
            'ecommerce': {
                'currency': 'RUB',
                'value': price || null,
                'items': [{
                    'item_name': name || '',
                    'item_id': id || null,
                    'discount': discountPrice || null,
                    'price': oldPrice || price || null,
                    'item_brand': brand || '',
                    'item_category': firstCategory || '',
                    'item_category2': lastCategory || '',
                    'item_category3': author || '',
                    'item_category4': type || '',
                    'item_list_name': list || null,
                }],
            },
        });

        if (eventLabel === 'ab_short') {
            window.location.href = isLocalhost(true) ? 'https://dev.eksmo.ru/cart/#/personal/cart' : 'https://eksmo.ru/cart/#/personal/cart';
        }
    },

    // Добавление нескольких товаров в корзину для блока "Вместе дешевле" GA-4
    addSomeBookGA4(oBooks, textBtnBasket) {
        _sendToDL({
            'event': 'add_to_cart',
            'eventCategory': textBtnBasket || '',
            'ecommerce': oBooks,
        });
    },

    // Событие начала оформления заказа в WL корзине GA-4
    checkoutBtnGA4(totalPrice, promocode, basketItemsCommon) {
        _sendToDL({
            'event': 'view_cart',
            'eventCategory': null,
            'ecommerce': {
                'currency': 'RUB',
                'coupon': promocode || '',
                'value': totalPrice,
                'items': basketItemsCommon,
            },
        });
    },

    // Событие начала оформления заказа в WL корзине после авторизации (фактический переход ко 2-у шагу оформления) GA-4
    beginCheckoutAuthGA4(totalPrice, promocode, basketItemsCommon) {
        _sendToDL({
            'event': 'begin_checkout',
            'eventCategory': null,
            'ecommerce': {
                'currency': 'RUB',
                'coupon': promocode || '',
                'value': totalPrice,
                'items': basketItemsCommon,
            },
        });
    },

    // Событие начала оформления заказа в WL корзине до авторизации GA-4
    beginCheckoutGA4() {
        _sendToDL({
            'event': 'eksmo',
            'eventAction': 'click',
            'eventCategory': 'Перейти к оформлению',
            'eventLabel': 'null',
        });
    },

    // Событие удаления книги из корзины
    removeFromCartGA4(totalPrice, basketItemsCommon) {
        _sendToDL({
            'event': 'remove_from_cart',
            'eventCategory': null,
            'ecommerce': {
                'currency': 'RUB',
                'value': totalPrice,
                'items': basketItemsCommon,
            },
        });
    },

    // Событие завершения оформления заказа в корзине GA-4
    orderSaveGA4(
        isPreorder,
        totalPrice,
        transactionId,
        promocode,
        shippingTier,
        shippingPrice,
        paymentType,
        useLoyalty,
        loyaltyAmount,
        basketItemsCommon,
    ) {
        _sendToDL({
            'event': 'purchase',
            'eventCategory': isPreorder,
            'shipping_tier': shippingTier || '',
            'payment_type': paymentType || '',
            'useLoyalty': useLoyalty || false,
            'loyaltyAmount': loyaltyAmount || null,
            'ecommerce': {
                'transaction_id': transactionId,
                'currency': 'RUB',
                'coupon': promocode || '',
                'shipping': shippingPrice || 0,
                'value': totalPrice || '',
                'items': basketItemsCommon,
            },
        });
    },

    // Отмена заказа в ЛК GA-4
    cancelOrderGA4(data) {
        _sendToDL({
            'event': 'refund',
            'eventCategory': data.reason,
            'ecommerce': {
                'transaction_id': data.orderId,
                'currency': 'RUB',
                'coupon': data.coupon || '',
                'value': null,
                'shipping': null,
            },
        });
    },

    // Событие просмотра промо-блока (например баннера) GA-4
    promoImpressionsGA4(objPromo) {
        if (isObject(objPromo)) {
            const arRes = [];

            Object.keys(objPromo).forEach((k) => {
                if (!_isResolvedTypeItem(objPromo[k])) {
                    delete objPromo[k];
                }
            });

            if (_isNoEmptyStrings([
                objPromo.id,
                objPromo.name,
            ])) { // обязательные поля
                if (!this.settings.viewedPromoGA4.includes(objPromo.name + objPromo.id)) {
                    this.settings.viewedPromoGA4.push(objPromo.name + objPromo.id);
                    arRes.push(objPromo);
                }
            }

            if (arRes.length) {
                _sendToDL({
                    'event': 'view_promotion',
                    'eventCategory': null,
                    'ecommerce': {
                        'promotion_id': objPromo.id || null,
                        'creative_name': objPromo.name || null,
                        'creative_slot': objPromo.creative_slot || null,
                        'location_id': objPromo.location_id || null,
                    },
                });
            }
        }
    },

    // Событие клика на промо-блок (например баннер) GA-4
    promoClickGA4(objPromo) {
        if (Object.prototype.toString.call(objPromo) === '[object Object]') {
            Object.keys(objPromo).forEach((k) => {
                if (!_isResolvedTypeItem(objPromo[k])) {
                    delete objPromo[k];
                }
            });

            if (_isNoEmptyStrings([
                objPromo.id,
                objPromo.name,
            ])) { // обязательные поля
                _sendToDL({
                    'event': 'select_promotion',
                    'eventCategory': null,
                    'ecommerce': {
                        'promotion_id': objPromo.id || null,
                        'creative_name': objPromo.name || null,
                        'creative_slot': objPromo.creative_slot || null,
                        'location_id': objPromo.location_id || null,
                    },
                });
            }
        }
    },

    // Событие подписки на рассылку
    eventSubscribe(name) {
        if (isNoEmptyString(name)) {
            _sendToDL({
                event: 'eksmo',
                eventAction: 'engagement',
                eventCategory: 'Подписка на рассылку',
                eventLabel: name,
            });
        }
    },

    // Событие отправки формы обратной связи
    eventFormFeedback() {
        _sendToDL({
            event: 'eksmo',
            eventAction: 'engagement',
            eventCategory: 'FAQ форма отправлена',
            eventLabel: $('title').first().text(),
        });
    },

    // Событие отправки формы рукописи (/site2018/author-form.php)
    eventFormBook() {
        _sendToDL({ 'event': 'PMfrmBook' });
    },

    // Событие шэринга в соц. сети
    eventSnShare(sn) {
        if (isNoEmptyString(sn)) {
            _sendToDL({
                event: 'eksmo',
                eventAction: 'engagement',
                eventCategory: 'Клики по кнопкам “поделиться” в социальных сетях',
                eventLabel: sn,
            });
        }
    },

    // Событие клика по ссылке в тексте статьи
    eventTextClickLink(event, label, action) {
        if (isNoEmptyString(event) && isNoEmptyString(label) && isNoEmptyString(action)) {
            _sendToDL({ 'event': event, 'Label': label, 'category': 'journal', 'action': action });
        }
    },

    // Событие клика по ссылке в тексте "теста"
    eventTestClickLink(label) {
        if (isNoEmptyString(label)) {
            _sendToDL({ 'event': 'testClickLink', 'Label': label });
        }
    },
};

$body.on('addBookBasket.eksmo', (e, data) => {
    const arrBooksMindbox = [];

    setTimeout(() => {
        data.basket.products.forEach((item) => {
            const product = item?.product;

            arrBooksMindbox.push({
                'nomcode': product?.nomcode,
                'quantity': item?.quantity,
                'price': product?.amount?.discounted,
            });
        });

        eventSender.sendBooksInBasketMD(arrBooksMindbox);
    }, speed * 5);
});
