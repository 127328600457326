import { eksmoBP } from 'Lib/bp/bp';
import { onScreen } from 'Lib/on-screen/on-screen';
import { getW } from 'Lib/w/w';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import { onWindowLoaded } from 'Lib/on-window-loaded/on-window-loaded';

const $body = $('body');
const sImg = '.img-loader';
const cIMGLoad = 'img-loader_load';

export function imgLoader($el) {
    const $img = $el instanceof jQuery ? $el.find(sImg) : $(sImg);

    $img.each(function () {
        const $this = $(this);

        if ($this.hasClass('img-loader_not-xs') && getW() < eksmoBP.smMin) {
            return;
        }

        if ($this.hasClass('img-loader_not-sm') && getW() < eksmoBP.mdMin) {
            return;
        }

        const attrHError = $this.attr('data-img-loader-h');
        const attrWError = $this.attr('data-img-loader-w');
        const attrDelay = $this.attr('data-img-loader-delay');
        const hError = attrHError?.length ? parseInt(attrHError) : 0;
        const wError = attrWError?.length ? parseInt(attrWError) : 0;

        onScreen($this, ($item) => {
            if (typeof attrDelay === 'string') {
                if (attrDelay.length) {
                    setTimeout(() => loadSrcOrBg($item), parseInt(attrDelay));
                } else {
                    onWindowLoaded().then(() => loadSrcOrBg($item));
                }
            } else {
                loadSrcOrBg($item);
            }

            $item.addClass(cIMGLoad);
        }, hError, wError);
    });
}

imgLoader();

$body.on('eksmoPagenavAjax', (e) => {
    imgLoader($(e.target));
});

$body.on('eksmoFancyboxAfterLoad', (e, instance, current) => {
    imgLoader(current.$slide);
});

$body.on('init.fastViewBook', (e, current) => {
    imgLoader(current);
});