import { isTouch } from 'Lib/istouch/istouch';
import { speed } from 'Lib/speed/speed';
import { eventSender } from 'Lib/event-sender/event-sender';
import { showAuth } from 'Lib/show-auth/show-auth';
import { isLocalhost } from 'Lib/is-localhost/is-localhost';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const $body = $('body');
const cActive = 'js-active';
const arID = [];
const arType = [];
const cDrop = 'like__drop';
const sDrop = `.${cDrop}`;
const showSpeed = speed * 10;

export const oLike = {
    init(selector) {
        if (isNoEmptyString(selector)) {
            const that = this;
            const $selector = $(document).find(selector);

            if ($('html').hasClass('is-authorized')) {
                that.load($selector);
            } else {
                $body.on('bitrixDynamicCompositeSuccess.eksmo', () => {
                    if ($('html').hasClass('is-authorized')) {
                        that.load($selector);
                    }
                });
            }

            $selector.off('click.like mouseenter.like mouseleave.like');

            $selector.on('click.like', function (e) {
                e.preventDefault();
                that.change(this);
            });

            $selector.on('mouseenter.like', function () {
                if (!isTouch()) {
                    const $this = $(this);

                    $this.find(sDrop).remove();
                    that.setZindexBookLike($this, false);

                    if (!$this.hasClass(cActive)) {
                        $this.append(`<div class="${cDrop}"><div class="tip"><div class="tip__style">Добавить в избранное</div></div></div>`);
                    } else {
                        $this.append(`<div class="${cDrop}"><div class="tip"><div class="tip__style">Удалить из избранного</div></div></div>`);
                    }

                    that.setZindexBookLike($this, true);
                    $this.find(sDrop).show();
                }
            });

            $selector.on('mouseleave.like', function () {
                if (!isTouch()) {
                    const $this = $(this);

                    $this.find(sDrop).remove();
                    that.setZindexBookLike($this, false);
                }
            });
        }
    },

    load($selector) {
        if ($selector.length) {
            $selector.each(function () {
                const $this = $(this);
                const dataType = $this.attr('data-type');
                const dataID = $this.attr('data-id');

                if (dataType?.length && !arType.includes(dataType)) {
                    arType.push(dataType);
                }

                if (dataID?.length && !arID.includes(dataID)) {
                    arID.push(dataID);
                }
            });

            if (arType.length && arID.length && !isLocalhost()) {
                $.post('/ajax/like/list/', { type: arType, a_guid: arID }, (jsonData) => {
                    const data = $.parseJSON(jsonData);

                    for (let i = 0; i < data.length; i++) {
                        const dataItem = data[i];

                        if (isNoEmptyString(dataItem?.GUID) && typeof dataItem?.SELECTED === 'string' && dataItem?.SELECTED === 'Y') {
                            let $curLike = '';

                            if (dataItem.TYPE) {
                                $curLike = $selector.filter(`[data-id=${dataItem.GUID}]`).filter(`[data-type=${dataItem.TYPE}]`);
                            } else {
                                $curLike = $selector.filter(`[data-id=${dataItem.GUID}]`);
                            }

                            $curLike.addClass(cActive);
                        }
                    }
                });
            }
        }
    },

    change(el) {
        const $el = $(el);

        if ($el.length) {
            const dataType = $el.attr('data-type');
            const dataID = $el.attr('data-id');

            if (dataType?.length && dataID?.length) {
                $.get('/ajax/like/add/', { type: dataType, guid: dataID }, (jsonData) => {
                    if (jsonData.trim() === 'AUTH') {
                        showAuth();
                    } else {
                        let isAdd = false;
                        let data = $.parseJSON(jsonData);

                        data = data?.[dataID];

                        if (data?.SELECTED === 'Y') {
                            $el.addClass(cActive);
                            isAdd = true;
                        } else {
                            $el.removeClass(cActive);
                        }

                        $el.find(sDrop).remove();
                        this.setZindexBookLike($el, false);

                        if (!$el.hasClass(cActive)) {
                            $el.append(`<div class="${cDrop}">
                                            <div class="tip tip_orange">
                                                <div class="tip__style">Удалено из избранного</div>
                                            </div>
                                        </div>`);
                        } else {
                            $el.append(`<div class="${cDrop}">
                                            <div class="tip tip_orange">
                                                <div class="tip__style">Добавлено в избранное</div>
                                            </div>
                                        </div>`);
                        }

                        this.setZindexBookLike($el, true);
                        $el.find(sDrop).show();

                        setTimeout(() => {
                            $el.find(sDrop).remove();
                            this.setZindexBookLike($el, false);
                        }, showSpeed);

                        $body.trigger('like.eksmo', [$el, isAdd]);

                        if (dataType === 'products' || dataType === 'products_electro' || dataType === 'products_audio') {
                            let name;
                            const $book = $el.closest('.book');
                            const $articleBook = $el.closest('.article_book');
                            const $bookDetail = $el.closest('.book-page');

                            if ($book.length) {
                                name = $book.find('.book__name').first().text().trim();
                            } else if ($articleBook.length) {
                                name = $articleBook.find('.article__name').first().text().trim();
                            } else if ($bookDetail.length) {
                                name = $bookDetail.find('.book-page__card-title').first().text().trim();
                            }

                            if (isAdd) {
                                if (name?.length) {
                                    $body.trigger('likeTo.eksmo', [$el]);
                                }
                            } else if (name?.length) {
                                eventSender.customDL({
                                    event: 'eksmo',
                                    eventAction: 'click',
                                    eventCategory: 'Удаление из избранного',
                                    eventLabel: name,
                                });
                            }

                            eventSender.setWishListMD();
                        }
                    }
                });
            }
        }
    },

    setZindexBookLike($el, state = false) {
        const $like = $el.closest('.book__like, .author__like');
        const $slide = $el.closest('.owl-item');

        if ($slide.length) {
            $slide.css({ 'z-index': state ? 1 : 0 });
        } else if ($like.length) {
            $like.css({ 'z-index': state ? 1 : 0 });
        }
    },
};