import 'jquery';
import 'element-closest-polyfill';
import 'custom-event-polyfill';
import 'whatwg-fetch';
import 'Bem/site/cmenu/cmenu';
import 'Lib/on-error/on-error';
import 'Lib/font-load/font-load';
import 'Lib/touch-retina-to-html-tag/touch-retina-to-html-tag';
import 'Lib/es-page-open/es-page-open';
import 'Lib/on-width-resize/on-width-resize';
import { whiteLabel } from 'Lib/white-label/white-label';
import 'Lib/bitrix-events/bitrix-events';
import 'Lib/on-window-loaded/on-window-loaded';
import 'Bem/site/banner-top/banner-top';
import 'Bem/site/cheader/cheader';
import 'Lib/set-webpack-public-path/set-webpack-public-path';
import 'Bem/site/skeleton-load/skeleton-load';
import 'Bem/site/img-loader/img-loader';

/*
DELETE OLD LESS JS

Временно коменчу и после своего теста жду теста юзерами, скорее всего ничего не отвалится, так как нигде не используется.
Если с коммита прошло больше полугода - можно удалять как подключения так и файлы.
import 'Bem/site/menu2/menu2';
*/

const $faqBot = $('.faq-bot').first();
const $helper = $('.helper').first();

$('body').on('click', '.js-link-auth-forms, [data-fancybox]', (e) => {
    e.preventDefault();
});

$faqBot.one('click', '.faq-bot__btn, .faq-bot__close', () => {
    $faqBot.addClass('faq-bot_load');

    import('Bem/site/faq-bot/faq-bot.js').then(({ setEventClick }) => {
        setEventClick();
        $faqBot.removeClass('faq-bot_load');
    });
});

$helper.one('click', '.helper__btn', () => {
    $helper.addClass('helper_load');

    import('Bem/site/helper/helper.js').then(({ setEventClick }) => {
        setEventClick();
        $helper.removeClass('helper_load');
    });
});

window.addEventListener('load', () => {
    import('Vendor/fancybox/jquery.fancybox.min');
    import('Lib/fancybox-custom/fancybox-custom');
    import('Lib/auth/auth');
    import('Lib/vkontakte/vkontakte');
    import('Bem/site/ajs/ajs');
    import('Bem/site/auth/auth');
    import('Bem/site/m-menu/m-menu');
    import('Bem/site/footer/footer');
    import('Bem/site/ajax-form/ajax-form');
    import('Bem/site/pagenav-ajax/pagenav-ajax');
    import('Bem/site/form/form');
    import('Bem/site/auth-form/auth-form');
    import('Bem/site/mail-form/mail-form');
    import('Bem/site/popup-unified/popup-unified');
    import('Bem/site/cookie-accept/cookie-accept');
    import('Bem/site/csearch/csearch');
    import('Bem/site/arrow-up/arrow-up');
    import('Lib/js-user-exit/js-user-exit');
    import('Bem/site/popup18/popup18');
    import('Bem/site/checkbox-accept/checkbox-accept');
    import('Bem/site/cart/cart');
    import('Bem/site/switch-language/switch-language');
    import('Bem/site/version-visually-impaired/version-visually-impaired');
    import('Bem/site/link-drop-down/link-drop-down');
    import('Lib/selectbox/selectbox').then(({ oSelectbox }) => {
        oSelectbox.init('.selectbox');
    });
});

window.whiteLabel = whiteLabel;