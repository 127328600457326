window.isLoadedWindow = window?.isLoadedWindow || false;

$(window).on('load', () => {
    window.isLoadedWindow = true;
});

export function onWindowLoaded() {
    return new Promise((resolve) => {
        if (window.isLoadedWindow) {
            resolve();
        } else {
            $(window).on('load', resolve);
        }
    });
}