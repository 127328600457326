import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

export function getCookie(cookieName) {
    if (!isNoEmptyString(cookieName)) {
        return false;
    }

    const results = document.cookie.match(`(^|;) ?${cookieName}=([^;]*)(;|$)`);

    if (results) {
        return decodeURIComponent(results[2]);
    }

    return false;
}