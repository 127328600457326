import { getCookie } from 'Lib/cookies/cookies';

const $bannerTop = $('.banner-top');

function removeBannerCookie(cookieName) {
    if (cookieName.length) {
        const date = new Date(new Date().getTime() + 60 * 1000 * 60 * 24 * 30); // на 30 дней

        document.cookie = `EKSMO_${cookieName}_DISABLED=Y; path=/; domain=${window.location.hostname}; expires=${date.toUTCString()}; SameSite=None; Secure`;
    }
}

function setActiveBanner() {
    $bannerTop.each(function () {
        const $this = $(this);
        const name = $this.attr('data-name');

        if (typeof name === 'string' && getCookie(`EKSMO_${name}_DISABLED`) !== 'Y') {
            $this.height($('.banner-top__container').outerHeight(true)).addClass('banner-top_active');
        } else {
            $this.remove();
        }
    });
}

$bannerTop.on('click', function () {
    const $this = $(this);
    const name = $this.attr('data-name');

    if (typeof name === 'string') {
        removeBannerCookie(name);
        $this.remove();
    }
});

$(document).ready(setActiveBanner);
$('body').on('eksmoResizeWidth', setActiveBanner);