document.addEventListener('DOMContentLoaded', () => {
    const idCategory = document.querySelector('.js-es-page-open')?.getAttribute('data-catalog-id');

    if (idCategory && idCategory.length) {
        window.mindbox('sync', {
            operation: 'Website.ViewCategory',
            data: {
                viewProductCategory: {
                    productCategory: {
                        ids: {
                            website: idCategory,
                        },
                    },
                },
            },
            onSuccess() {
                console.log(' ♑ sendCategoryMD()', idCategory);
            },
            onError(error) {
                console.log(`Ошибка MD - ${error}`);
            },
        });
    }
});