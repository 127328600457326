import { eksmoBP } from 'Lib/bp/bp';
import { getCookie } from 'Lib/cookies/cookies';
import { getW } from 'Lib/w/w';
import fastdom from 'fastdom';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const $window = $(window);
const $body = $('body');
const $parent = $('.cheader').first();
const cFixed = 'cheader_fixed';
let hHeader = 0;
let hHeaderFixed = 0;
let isCalcHHeader = false;
let isCalcHHeaderFixed = false;
const hHeaderFixedM = 50;
const hHeaderFixedD = 56;
const sPersOffer = '.cheader__personal-offer';
const sBdlPersOffer = '.cheader__personal-offer_bdl';
const cPersOfferActive = 'cheader__personal-offer_active';
const sPersOfferLink = '.cheader__personal-offer-link';
const sPersOfferClose = '.cheader__personal-offer-close';
const cPassThrough = 'cheader__personal-offer_pass-through';

function setWLLocation() {
    if (getW() < eksmoBP.mdMin) {
        const $wlLocation = $('.cheader__location-cont-d .cheader__location');

        if ($wlLocation.length) {
            const $wlLocationRemoved = $wlLocation.detach();

            $('.cheader__location-cont-m').first().append($wlLocationRemoved);
        }
    }
}

function toggleFixed(isResetValues) {
    if (typeof isResetValues === 'boolean' && isResetValues) {
        isCalcHHeader = false;
        isCalcHHeaderFixed = false;
    }

    fastdom.measure(() => {
        const sTop = $(document).scrollTop();
        const isCFixed = $parent.hasClass(cFixed);

        if (!isCalcHHeaderFixed || !isCalcHHeader) {
            if ($parent.hasClass(cFixed)) {
                hHeaderFixed = $parent.outerHeight();
                isCalcHHeaderFixed = true;
            } else {
                hHeader = $parent.outerHeight();

                if (!hHeaderFixed) {
                    hHeaderFixed = getW() > eksmoBP.smMax ? hHeaderFixedD : hHeaderFixedM;
                }

                isCalcHHeader = true;
            }
        }

        fastdom.mutate(() => {
            if (sTop > hHeader - hHeaderFixed) {
                if (!isCFixed) {
                    $parent.addClass(cFixed);
                    $body.css({ 'padding-top': hHeader });
                    $parent.trigger('eksmoHeaderFixed', [true]);
                }
            } else if (isCFixed) {
                $parent.removeClass(cFixed);
                $body.css({ 'padding-top': 0 });
                $parent.trigger('eksmoHeaderFixed', [false]);
            }
        });
    });
}

function cookieShowedPersonalOffer(cookieName) {
    if (cookieName.length) {
        const date = new Date(new Date().getTime() + 60 * 1000 * 60 * 24 * 30); // на 30 дней

        document.cookie = `EKSMO_${cookieName}_SHOWED=Y; path=/; domain=${window.location.hostname}; expires=${date.toUTCString()}; SameSite=None; Secure`;
    }
}

function cookieDisablePersonalOffer(cookieName) {
    if (cookieName.length) {
        const date = new Date(new Date().getTime() + 60 * 1000 * 60 * 24 * 30); // на 30 дней

        document.cookie = `EKSMO_${cookieName}_DISABLED=Y; path=/; domain=${window.location.hostname}; expires=${date.toUTCString()}; SameSite=None; Secure`;
    }
}

if ($parent.length) {
    toggleFixed();
    $window.on('scroll', toggleFixed);

    $body.on('eksmoResizeWidth', () => {
        toggleFixed(true);
    });

    $('.m-menu').on('eksmoSetMMenu', setWLLocation);

    $(sPersOfferLink).on('click', function () {
        const $el = $(this).closest(sPersOffer);

        if ($el.length) {
            const name = $el.attr('data-name');

            if (isNoEmptyString(name)) {
                cookieDisablePersonalOffer(name);
            }
        }
    });

    $(sPersOfferClose).on('click', function () {
        const $el = $(this).closest(sPersOffer);

        if ($el.length) {
            const name = $el.attr('data-name');

            $el.removeClass(cPersOfferActive);

            if (isNoEmptyString(name)) {
                cookieDisablePersonalOffer(name);
            }
        }
    });

    $(`${sPersOffer}:not(${sBdlPersOffer})`).each(function () {
        const $this = $(this);
        const name = $this.attr('data-name');

        if (isNoEmptyString(name)) {
            if (getCookie(`EKSMO_${name}_DISABLED`) !== 'Y') {
                $this.addClass(cPersOfferActive);

                if ($this.hasClass(cPassThrough) && getCookie(`EKSMO_${name}_SHOWED`) !== 'Y') {
                    cookieShowedPersonalOffer(name);
                }
            }
        } else {
            $this.addClass(cPersOfferActive);
        }
    });

    $body.on('like.eksmo', (e, $el, isAdd) => {
        const $qtyFavorite = $parent.find('.cfavorite__qty');

        if ($el.length && $qtyFavorite.length && typeof isAdd === 'boolean') {
            const dataType = $el.attr('data-type');

            if (dataType?.length && dataType === 'products') {
                const currentNum = parseInt($qtyFavorite.html()) || 0;
                const resultNum = isAdd ? currentNum + 1 : currentNum - 1;

                $qtyFavorite.html(resultNum < 0 ? 0 : resultNum);
            }
        }
    });

    $('.cheader__version-visually-impaired').on('click', () => {
        $body.trigger('eksmoOpenVisuallyImpaired');
    });
}