export function getNumber(value, defaultNumber) {
    if (typeof value === 'number') {
        return value;
    }

    if (typeof value === 'string') {
        return parseInt(value);
    }

    return typeof defaultNumber === 'number' ? defaultNumber : 0;
}