import { getW } from 'Lib/w/w';

let width = getW();

$(window).on('resize', () => {
    const newWidth = getW();

    if (newWidth !== width) {
        $('body').trigger('eksmoResizeWidth', [newWidth, width]);
        width = newWidth;
    }
});